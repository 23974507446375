import React from 'react'

import Layout from '../../../../components/Layout'
import ToolsTabs from '../../../../components/marketplace/tools/ToolsTabs'

export default class AdminNavbar extends React.Component {
  render() {
    return (
      <Layout noFooter active="tools" requireAuth>
        <ToolsTabs active="acceptance-simulator" />
        <iframe
          title="brand_infogram"
          src="https://infogram.com/offer-acceptance-simulator-1hxj48d5nm892vg?live"
          className="full-page-iframe"
        />
      </Layout>
    )
  }
}
